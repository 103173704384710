export default {
  // apiUrl: "https://calendar.clasiparautos.com/api/",
  apiUrl: "https://iplanto.herokuapp.com/",

  async get(action, params = {}) {
    let paramsStr = "";
    if (Object.keys(params).length > 0) {
      const query = new URLSearchParams(params);
      paramsStr = "?" + query;
    }

    let url = this.apiUrl + action + paramsStr;

    // console.log('Calling API:', url);
    try {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    } catch (e) {
      console.error(e);
      throw new Error("Failed to fetch " + url);
    }
  },

  async post(action, params = {}) {
    let url = this.apiUrl + action;
    // console.log('Calling API POST:', url);
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      return await res.json();
    } catch (e) {
      console.error(e);
      throw new Error("Failed to post data to " + url);
    }
  },
};
