export default {
  slackUrl: process.env.VUE_APP_SLACK_URL,

  async post(params = {}) {
    try {
      const res = await fetch(this.slackUrl, {
        method: "POST",
        headers: {
          //   "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      return;
    } catch (e) {
      console.error(e);
      throw new Error("Failed to post data to Slack");
    }
  },
};
