<template>
  <div id="app">
    <div class="topnav">
      <div class="topnav-right">
        <a
          href="#"
          @click.prevent="saveProgressPopup"
          v-if="!userEmail && isActiveToday"
          >Save</a
        >
        <a
          href="#"
          @click.prevent="$refs.newCalendarPopup.toggle()"
          v-if="userGoal || daysPassed !== 1"
          >Create</a
        >
        <a
          href="#"
          style="padding-right: 20px"
          @click.prevent="$refs.aboutPopup.toggle()"
          >About</a
        >
      </div>
    </div>

    <div
      class="goal-description"
      v-if="calendarUID !== 0 && calendarUID !== null && calendarUID !== -1"
    >
      I plan to
      <span class="user-input" @click.prevent="inputGoalPopup(-1)">{{
        userGoal ? userGoal : "____"
      }}</span>
      for <span class="no-wrap">30 days</span>
    </div>

    <div v-if="calendarUID === -1" class="message">
      Loading your unique plan...
    </div>
    <div v-else-if="calendarUID === 0 || calendarUID === null" class="message">
      Not found.
      <a href="#" @click.prevent="$refs.newCalendarPopup.toggle()"
        >Create a new 30 day plan!</a
      >
    </div>
    <div class="calendar-container" v-else>
      <div class="calendar">
        <div
          v-for="(day, index) in days"
          :class="{
            day: true,
            muted: day.isMuted,
            today: day.isToday && !isAdmin,
            editable: day.isEditable,
          }"
          @click="userGoal ? toggleState(index) : inputGoalPopup(index)"
          :key="index"
        >
          <div class="title">Day {{ day.day }}</div>
          <div class="subtitle">
            {{ day.title }}
          </div>
          <div class="content">
            <img v-if="day.state === 1" src="./assets/done.png" />
            <span v-else-if="day.isEditable && userGoal"> Mark as done </span>
            <span v-else-if="day.isEditable"> Set goal first </span>
          </div>
        </div>
      </div>
      <div class="calendar-header">
        <div
          v-for="(name, index) in ['s', 'm', 't', 'w', 't', 'f', 's']"
          class="day-name"
          :key="`day-${index}`"
        >
          {{ name }}
        </div>
      </div>
    </div>

    <div class="goalswon-label">
      <span v-if="daysTicked > 0"
        >Well done on
        {{ daysTicked !== 1 ? daysTicked + " days" : daysTicked + " day" }} so
        far. </span
      >Want an accountability coach?
      <span class="no-wrap"
        >Get
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.goalswon.com/?utm_source=iplanto&utm_medium=sideproject&utm_campaign=iplanto"
          >GoalsWon</a
        ></span
      >
    </div>

    <footer id="footer">
      <div
        class="a2a_kit a2a_kit_size_32 a2a_default_style"
        data-a2a-url="https://iplan.to/"
        data-a2a-title="Fun way to stick to a new habit"
        id="centered_buttons"
      >
        <p class="share-label">Share</p>
        <a class="a2a_button_copy_link"></a>
        <a class="a2a_button_facebook"></a>
        <a class="a2a_button_twitter"></a>
        <a class="a2a_button_linkedin"></a>
      </div>
    </footer>

    <Popup ref="newCalendarPopup">
      <h2>Create another plan?</h2>
      <p>
        This creates a new "I plan to <span class="orange-text">____</span>"
        separate from this one.
      </p>
      <div
        class="button"
        @click="
          openNewCalendar();
          $refs.newCalendarPopup.toggle();
        "
      >
        Create
      </div>
      <div
        class="button cancel"
        @click="$refs.newCalendarPopup.toggle()"
        style="margin-left: 20px; font-weight: normal"
      >
        Cancel
      </div>
    </Popup>

    <Popup ref="saveProgressPopup">
      <h1>How Saving Works</h1>
      <div v-if="!isProgressSaved">
        <p>
          This page auto-saves and you'll redirect here when returning on this
          device.
        </p>
        <p>Want us to send you the direct link?</p>
        <p>
          <input
            ref="emailSaveProgressPopup"
            v-model="tempEmail"
            type="email"
            class="text-field"
            placeholder="Your email"
            maxlength="200"
            autocorrect="off"
            autocapitalize="none"
          />
        </p>
        <p></p>
        <div
          class="button"
          @click="saveProgress()"
          :class="{ disabled: !isValidEmail }"
        >
          Send me the link
        </div>
      </div>
      <div v-else>
        <img
          src="./assets/done.png"
          style="margin: 10px auto; width: 60px; display: block"
        />
        We sent the link to your email! Check your inbox for an email from
        <a :href="'https://iplan.to/' + calendarUID">iplan.to</a>
      </div>
    </Popup>

    <Popup ref="progressPopup">
      <h1 v-html="progressTitle"></h1>
      <p
        v-html="progressText"
        v-if="!progressShowEmail || (progressShowEmail && !isProgressSaved)"
      ></p>
      <div v-if="progressShowEmail && !isProgressSaved">
        <p v-if="!finished">Enter your email for a reminder:</p>
        <p v-else>
          Enter your email for a personal certificate to celebrate your success!
        </p>
        <p>
          <input
            ref="emailProgressPopup"
            v-model="tempEmail"
            type="email"
            class="text-field"
            placeholder="Your email"
            maxlength="200"
            autocorrect="off"
            autocapitalize="none"
          />
        </p>
        <div
          @click.stop="finished ? sendCertificate() : saveProgress()"
          class="button"
          :class="{ disabled: !isValidEmail }"
        >
          Ok!
        </div>
      </div>
      <div v-else-if="progressShowEmail">
        <img
          src="./assets/done.png"
          style="margin: 10px auto; width: 60px; display: block"
        />
        <p v-if="!finished">
          We sent the link to your email! Check your inbox for an email from
        </p>
        <p v-else>Done! Check your inbox for an email from</p>
        <a :href="'https://iplan.to/' + calendarUID">iplan.to</a>
      </div>
    </Popup>

    <Popup ref="inputGoalPopup">
      <h1>Your goal</h1>
      <p>
        <input
          ref="goalInputGoalPopup"
          v-model="userGoal"
          type="text"
          class="text-field bigger"
          placeholder="eg- meditate"
          maxlength="30"
          autocorrect="off"
          autocapitalize="none"
        />
      </p>
      <div
        @click.stop="
          saveUser();
          $refs.inputGoalPopup.toggle();
        "
        class="button"
      >
        Ok!
      </div>
    </Popup>

    <Popup ref="aboutPopup">
      <h2>Don't break the chain!</h2>
      <div class="about-text">
        <p>
          <a :href="'https://iplan.to/' + calendarUID">iplan.to</a> is a simple
          and free 30 day habit tracker.
        </p>
        <p>
          How it works: set your goal then tick the days as you build your
          streak!
        </p>
        <p>Your plan has its own unique URL and progress is autosaved.</p>
        <p>
          If you want to start over or make a new plan, hit the Create button.
        </p>
        <p>
          Our privacy policy is
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/document/d/e/2PACX-1vSCMRO_NN0v4DDyYrFDnRVDNBVP838MGVeUPmRTr8YVX8ZDI2pCeb8VRYxCCITjlSdj5axal_q8DEjR/pub"
            >here</a
          >.
        </p>
        <p>
          If you have a question or request
          <a
            :href="
              'mailto:iplanto@goalswon.com?subject=About%20iplan.to%20Our%20(ref:%20[' +
              Math.floor(Math.random() * 9999) +
              1 +
              '])&body=Hi%20iplan.to%20team,%20my%20message%20is%20-%20'
            "
            target="_blank"
            rel="noopener noreferrer"
            >get in touch</a
          >.
        </p>
        <p>
          ps - created by Joel and Simon, co-founders of GoalsWon, an
          accountability service.
          <a
            href="https://www.goalswon.com/?utm_source=iplanto&utm_medium=sideproject&utm_campaign=iplanto"
            target="_blank"
            rel="noopener noreferrer"
            >Learn more</a
          >.
        </p>
      </div>
      <div
        class="button"
        @click="$refs.aboutPopup.toggle()"
        style="margin: 20px 0 0 0"
      >
        Ok!
      </div>
    </Popup>
  </div>
</template>

<script>
import API from "./API";
import SLACK from "./SLACK";
import SLACKEMAIL from "./SLACKEMAIL";
import confetti from "canvas-confetti";
import Popup from "./components/Popup";

export default {
  name: "App",
  components: { Popup },
  data() {
    return {
      days: [],
      calendarUID: -1,
      userGoal: "",
      userEmail: "",
      isShowNewCalendarPopup: false,
      isProgressSaved: false,
      progressTitle: "",
      progressText: "",
      progressShowEmail: false,
      daysPassed: 0,
      finished: false,
      daysTicked: 0,
      isActiveToday: false,
      isAdmin: false,
      isValidEmail: false,
      regexEmail: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      tempEmail: "",
    };
  },
  watch: {
    userGoal(value) {
      if (value.trim() === "") {
        this.userGoal = "";
      }
    },
    tempEmail(value) {
      if (value.trim() === "") {
        this.tempEmail = "";
        this.isValidEmail = false;
      }
      if (!this.regexEmail.test(value)) {
        this.isValidEmail = false;
      } else {
        this.isValidEmail = true;
      }
    },
  },
  computed: {},
  methods: {
    toggleState(dayIndex) {
      if (!this.days[dayIndex].isEditable) {
        return false;
      }

      this.isActiveToday = true;

      let newState = 1;
      if (this.days[dayIndex].state === 1) {
        newState = 0;
      }

      this.days[dayIndex].state = newState;

      const daysCompleted = this.days.reduce((acc, cur) => {
        return acc + (cur.state === 1 ? 1 : 0);
      }, 0);

      this.daysTicked = daysCompleted;

      if (newState === 1 && !this.isAdmin) {
        this.progressShowEmail = false;
        this.isProgressSaved = false;
        this.progressTitle = "";
        this.progressText = "";

        if (this.days[dayIndex].day === 30) {
          this.progressTitle = "Congratulations on finishing!";
          this.progressText =
            "You completed with " +
            daysCompleted +
            " days of " +
            (this.userGoal == "" ? "your goal" : this.userGoal);

          this.progressShowEmail = true;
          this.finished = true;
          setTimeout(() => this.$refs.progressPopup.toggle(), 250);
          if (!this.userEmail) {
            this.$nextTick(() => {
              this.$refs.emailProgressPopup.focus();
            });
          }
          confetti({ gravity: 0.5, spread: 90, particleCount: 120 });
        } else {
          switch (daysCompleted) {
            case 1:
              this.progressTitle = "Great start!";
              this.progressText = `Come back tomorrow to grow your streak.`;
              this.progressShowEmail = this.userEmail ? false : true;
              setTimeout(() => this.$refs.progressPopup.toggle(), 250);
              if (!this.userEmail) {
                this.$nextTick(() => {
                  this.$refs.emailProgressPopup.focus();
                });
              }
              confetti({ gravity: 0.5, spread: 90, particleCount: 120 });
              break;
            case 2:
              this.progressTitle = "2 days done!";
              this.progressText =
                "You proved it wasn't just a fluke. Let's make it 3 tomorrow...";
              setTimeout(() => this.$refs.progressPopup.toggle(), 250);
              confetti({ gravity: 0.5, spread: 90, particleCount: 120 });
              break;
            case 3:
              this.progressTitle = "3 days done!";
              this.progressText = "You're on a roll! See you tomorrow...";
              setTimeout(() => this.$refs.progressPopup.toggle(), 250);
              confetti({ gravity: 0.5, spread: 90, particleCount: 120 });
              break;
            case 7:
              this.progressTitle = "7 days done!";
              this.progressText =
                "A week's worth of progress, bravo! See you tomorrow...";
              this.progressShowEmail = this.userEmail ? false : true;
              setTimeout(() => this.$refs.progressPopup.toggle(), 250);
              if (!this.userEmail) {
                this.$nextTick(() => {
                  this.$refs.emailProgressPopup.focus();
                });
              }
              confetti({ gravity: 0.5, spread: 90, particleCount: 120 });
              break;
            case 10:
              this.progressTitle = "10 days done!";
              this.progressText =
                "ps - want an accountability coach to take you further? Get <a target='_blank' rel='noopener noreferrer' href='https://www.goalswon.com/?utm_source=iplanto&utm_medium=sideproject&utm_campaign=iplanto'>GoalsWon</a>";
              setTimeout(() => this.$refs.progressPopup.toggle(), 250);
              confetti({ gravity: 0.5, spread: 90, particleCount: 120 });
              break;
            case 14:
              this.progressTitle = "14 days done!";
              this.progressText =
                "Your dreams are coming true. Let's keep this rolling...";
              this.progressShowEmail = this.userEmail ? false : true;
              setTimeout(() => this.$refs.progressPopup.toggle(), 250);
              if (!this.userEmail) {
                this.$nextTick(() => {
                  this.$refs.emailProgressPopup.focus();
                });
              }
              confetti({ gravity: 0.5, spread: 90, particleCount: 120 });
              break;
            case 20:
              this.progressTitle = "20 days done!";
              this.progressText = `Savor this achievement! And don't forget to ${
                this.userGoal ? this.userGoal : "your goal"
              } tomorrow!`;
              setTimeout(() => this.$refs.progressPopup.toggle(), 250);
              confetti({ gravity: 0.5, spread: 90, particleCount: 120 });
              break;
          }
        }

        SLACK.post({
          text: `${daysCompleted}/${
            this.daysPassed !== 0 ? this.daysPassed : 30
          } ${this.userGoal} ${this.userEmail}`,
        });
      }

      API.get("index.php", {
        action: "set_state",
        uid: this.calendarUID,
        state: newState,
        day: this.days[dayIndex].day - 1,
      });
    },
    saveProgressPopup() {
      this.$refs.saveProgressPopup.toggle();
      if (!this.userEmail) {
        this.$nextTick(() => {
          this.$refs.emailSaveProgressPopup.focus();
        });
      }
    },
    async createCalendar() {
      try {
        const res = await API.get("index.php", {
          action: "create_calendar",
          date: new Date().toDateString(), // pass client's date in "Thu Apr 12 2018" format, so it not depending on server time
        });
        return res.uid;
      } catch (e) {
        alert("Oops - failed to create the plan.");
        // console.log("Error", e);
      }
    },
    async saveUser() {
      if (this.userGoal.trim()) {
        await API.get("index.php", {
          action: "save_user",
          uid: this.calendarUID,
          goal: this.userGoal.trim(),
        });
        if (this.userGoal) {
          document.title = `iplan.to ${this.userGoal}`;
        }
      }
    },
    async saveProgress() {
      if (!this.isValidEmail) {
        return false;
      }

      try {
        await API.get("index.php", {
          action: "save_progress",
          uid: this.calendarUID,
          email: this.tempEmail,
        });

        this.userEmail = this.tempEmail;

        SLACKEMAIL.post({
          text: this.userEmail,
        });

        this.isProgressSaved = true;
      } catch (e) {
        alert("Oops - something went wrong while submitting");
      }
    },
    async sendCertificate() {
      if (!this.isValidEmail) {
        return false;
      }

      try {
        await API.get("index.php", {
          action: "send_certificate",
          uid: this.calendarUID,
          email: this.tempEmail,
          completed: this.daysTicked,
        });
        this.userEmail = this.tempEmail;

        SLACKEMAIL.post({
          text: this.userEmail,
        });

        this.isProgressSaved = true;
      } catch (e) {
        alert("Oops - something went wrong while submitting");
      }
    },
    async openNewCalendar() {
      const uid = await this.createCalendar();
      this.$cookies.set("uid", uid);
      const win = window.open(window.location.origin + "/" + uid, "_blank");
      win.focus();
    },
    inputGoalPopup(dayIndex) {
      if (dayIndex !== -1 && !this.days[dayIndex].isEditable) {
        return false;
      }
      this.$refs.inputGoalPopup.toggle();
      this.$nextTick(() => {
        this.$refs.goalInputGoalPopup.focus();
      });
    },
  },
  async created() {
    const ur = window.location.search.substring(1);
    const param = new URLSearchParams(ur);
    const admin = param.get("sdmn");

    if (admin) {
      this.isAdmin = true;
    }

    const urlUID = window.location.pathname.split("/")[1];

    if (urlUID == "") {
      // check cookies
      if (this.$cookies.get("uid")) {
        window.location = "/" + this.$cookies.get("uid");
      }

      // generate new
      else {
        const uid = await this.createCalendar();
        if (uid) {
          this.$cookies.set("uid", uid);
          let preloadGoalTemp = "";
          let uri = window.location.search.substring(1);
          let params = new URLSearchParams(uri);
          preloadGoalTemp = params.get("g") ? "?g=" + params.get("g") : "";

          window.location = "/" + uid + preloadGoalTemp;
          urlUID = uid;
        }
      }
    }

    try {
      var calendarData = await API.get("index.php", {
        action: "get_calendar",
        uid: urlUID,
      });

      this.calendarUID = calendarData.uid;
      this.userGoal = calendarData.goal;
      this.userEmail = this.tempEmail = calendarData.email;

      let preloadGoal = "";
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      preloadGoal = params.get("g");

      if (preloadGoal) {
        this.userGoal = preloadGoal;
      }

      if (this.userGoal) {
        document.title = `iplan.to ${this.userGoal}`;
      }
    } catch (e) {
      this.calendarUID = 0;
    }

    // format start date: first take it from DB, then sets with setDate() setMonth() setFullYear() so its not depending on timezone
    let startDay = new Date();
    let date = calendarData.start_day.split("-"); // DB date format: "2020-12-15"
    startDay.setDate(date[2]);
    startDay.setMonth(date[1] - 1); // month should be in 0-11 format
    startDay.setFullYear(date[0]);
    startDay.setHours(0, 0, 0, 0);

    let today = new Date();
    today.setHours(0, 0, 0, 0); // need to round date

    let days = [];

    // fill days of week before start day
    const startDayOfWeek = startDay.getDay(); // === 0 ? 6 : startDay.getDay() - 1; // we need to change as we use Mon as first day of week
    for (let i = startDayOfWeek; i > 0; i--) {
      const curDate = new Date(startDay);
      curDate.setDate(curDate.getDate() - i); // substract N days
      days.push({
        title:
          curDate.getDate() +
          " " +
          curDate.toLocaleString("en-US", { month: "short" }),
        isMuted: true,
      });
    }

    let daysInMonth = new Date(
      startDay.getFullYear(),
      startDay.getMonth() + 1,
      0
    ).getDate();

    for (let i = 0; i < 30; i++) {
      const curDate = new Date(startDay);
      curDate.setDate(curDate.getDate() + i); // add N days
      curDate.setHours(0, 0, 0, 0); // round all dates
      const isToday =
        curDate.getMonth() === today.getMonth() &&
        curDate.getDate() === today.getDate();
      if (isToday) {
        this.daysPassed = i + 1;
      }
      // try to find this day in saved data
      const dayData = calendarData.days.find(
        (el) =>
          el.date ===
          `${curDate.getFullYear().toString()}-${(curDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${curDate.getDate().toString().padStart(2, "0")}`
      ); // TODO if el.date is 0 or corrupted on backend - 1 day selected
      days.push({
        title:
          curDate.getDate() +
          " " +
          curDate.toLocaleString("en-US", { month: "short" }),
        timestamp: curDate.getTime(),
        isToday: isToday,
        isMuted: false,
        isEditable: curDate <= today,
        state: dayData ? dayData.state : 0,
        day: i + 1,
      });
    }

    // fill days of week after finish day
    const addDays = startDayOfWeek === 6 ? 6 : 5 - startDayOfWeek;
    for (let i = 0; i < addDays; i++) {
      const curDate = new Date(startDay);
      curDate.setDate(curDate.getDate() + 30 + i); // add N days
      days.push({
        title:
          curDate.getDate() +
          " " +
          curDate.toLocaleString("en-US", { month: "short" }),
        isMuted: true,
      });
    }
    this.days = days;

    this.daysTicked = this.days.reduce((acc, cur) => {
      return acc + (cur.state === 1 ? 1 : 0);
    }, 0);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  min-height: 850px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

html,
body {
  margin: 0;
  height: 100%;
}

::selection {
  background: #ffa500; /* WebKit/Blink Browsers */
  color: white;
}
::-moz-selection {
  background: #ffa500; /* Gecko Browsers */
  color: white;
}

#footer {
  width: 100%;
  line-height: 25px;
}

a {
  color: #ff9900;
}

.orange-text {
  color: #ff9900;
}

.no-wrap {
  white-space: nowrap;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: white;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #666;
  text-align: center;
  padding: 14px 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

/* Change the color of links on hover */
.topnav a:hover:not(.logo) {
  background-color: white;
  color: #c07300;
}

/* Add a color to the logo/current link */
.topnav a.logo {
  color: black;
  font-weight: 700;
  font-size: 17px;
  font-family: "Quicksand", sans-serif;
  padding: 11px 10px;
}

/* Right-aligned section inside the top navigation */
.topnav-right {
  float: right;
}

/* Dropdown container - needed to position the dropdown content */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  font-size: 14px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 44px;
  right: 0px;
  padding: 12px 16px;
}

/* Style the links inside the dropdown */
.dropdown-content input,
.dropdown-content label {
  float: none;
  text-decoration: none;
  text-align: left;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
  font-size: 14px;
}

.share-label {
  margin: 0 0 5px 0;
  color: #ccc;
  font-size: 14px;
  position: absolute;
  margin-left: -210px;
  margin-top: -2px;
}

#centered_buttons {
  display: flex;
  justify-content: center;
}

.a2a_kit {
  padding-bottom: 25px !important;
}

.a2a_default_style a {
  padding: 0 6px !important;
}

.a2a_default_style .a2a_svg {
  border-radius: 30px !important;
  background-color: #ccc !important;
}

.a2a_svg {
  height: 24px !important;
  padding: 2px !important;
  width: 24px !important;
}

.button {
  cursor: pointer;
  margin: 8px 0;
  border: 1px solid #ffa500;
  background-color: #ffa500;
  color: white;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 5px;
  font-weight: bold;
  white-space: nowrap;
}

.button.disabled {
  background-color: #ccc !important;
  border-color: #ccc !important;
}

.disabled {
  cursor: not-allowed !important;
}

.button.cancel {
  background-color: white;
  border-color: #b9b9b9;
  color: #919090;
}

.button:hover:not(.cancel):not(.disabled) {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.button.cancel:hover {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.button img {
  width: 18px;
  vertical-align: middle;
  margin-right: 4px;
}

.text-field {
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  display: block;
  margin: auto;
  padding: 7px 12px;
  box-sizing: border-box;
}

.text-field.bigger {
  font-size: 24px;
}

.message {
  font-size: 1.6em;
  padding: 50px;
}

.goal-description {
  width: 90%;
  font-size: 2.2rem;
  padding: 0 5vw;
  font-weight: 700;
  max-width: 800px;
  align-self: center;
}

.goal-description input,
.user-input {
  border: 0;
  color: #ffa500;
  font-weight: bold;
  font-size: 2.2rem;
  text-align: center;
}

.goal-description input::placeholder {
  font-style: italic;
  font-weight: normal;
}

.user-input[placeholder]:empty:before {
  content: attr(placeholder);
  color: #ccc;
  font-style: italic;
}

.user-input[placeholder]:empty:focus:before {
  content: "";
}

.calendar-container {
  width: 95%;
  max-width: 800px;
  align-self: center;
  padding-left: 1%;
}

.calendar-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.calendar-header .day-name {
  width: calc(98% / 7);
  line-height: 30px;
  font-size: 0.8em;
  color: #ccc;
  font-weight: 700;
}

.calendar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.calendar .day {
  width: calc(97% / 7);
  border: 1px solid black;
  margin: 0 -1px -1px 0;
}

.calendar .day .title {
  line-height: 30px;
  font-size: 0.9em;
  font-weight: 700;
}

.calendar .day .subtitle {
  line-height: 20px;
  font-size: 0.9em;
  color: #ccc;
}

.calendar .day .content {
  height: 7vh;
  max-height: 70px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-height: 35px;
}
.calendar .day .content span {
  padding: 20px 0;
  display: block;
  color: white;
}
.calendar .day .content img {
  width: 30%;
}

.calendar .day.editable {
  cursor: pointer;
}

.calendar .day.editable:hover {
  z-index: 3;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
}
.calendar .day:hover .content span {
  color: #444;
}

.calendar .day.muted {
  opacity: 0.5;
  visibility: hidden;
}
.calendar .day.today .title {
  background-color: #ffa500;
  color: white;
}

.about-text {
  width: 90%;
  margin: auto;
  text-align: left;
}

.goalswon-label {
  align-self: center;
  width: 90%;
}

@media (max-width: 576px) {
  #app {
    min-height: 650px;
  }

  h1 {
    font-size: 1.5em;
  }

  header .menu div {
    font-size: 0.9em;
  }

  .topnav a {
    font-size: 15px;
  }

  .dropdown-content.active,
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .goal-description {
    font-size: 1.5rem;
    min-height: 50px;
  }

  .goal-description input,
  .user-input {
    font-size: 1.5rem;
  }

  .calendar-header .day-name {
    line-height: 20px;
  }

  .calendar .day .title {
    line-height: 15px;
    font-size: 0.75em;
    padding: 2px 0;
    border-bottom: 0;
  }

  .calendar .day .subtitle {
    line-height: 14px;
    font-size: 0.7em;
  }

  .calendar .day .content {
    height: 5vh;
  }

  .calendar .day .content img {
    width: 50%;
  }

  .calendar .day .content span {
    display: none;
  }

  .a2a_kit {
    padding-bottom: 20px;
  }

  .share-label {
    font-size: 14px;
  }

  .goalswon-label {
    line-height: 24px;
  }
}
</style>
