<template>
  <div :class="{ modal: true, open: this.open }" @click.stop="toggle()">
    <div class="modal-content" @click.stop="">
      <span class="close" @click.stop="toggle()">&times;</span>
      <p><slot></slot></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.open {
  display: block;
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  position: relative;
  z-index: 500;
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0 7px 21px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 7px 21px rgba(0, 0, 0, 0.3);
  box-shadow: 0 7px 21px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 700px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
